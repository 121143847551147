import React from "react"
import { RouteComponentProps } from "@reach/router"
import { connect, ConnectedProps } from "react-redux"
import { navigate, Link } from "gatsby"

import { store } from "@/types/interface"
import { courseFilter, courseTypeLoad } from "../../actions"
import { CourseCard, SEO } from "../../components/common"
import styles from "./course-type.module.css"

const connector = connect(
  // mapStateToProps
  ({
    course,
    courseType,
  }: {
    course: store.Course
    courseType: store.CourseType
  }) => ({
    courseAbstracts: course?.courseAbstracts,
    courseTypes: courseType.courseTypes,
  }),
  // mapDispatchToProps
  { courseFilter, courseTypeLoad }
)

type Props = RouteComponentProps<{ courseTypeId: string }> &
  ConnectedProps<typeof connector>

const CourseType: React.FC<Props> = ({
  courseTypeId,
  courseAbstracts,
  courseFilter,
  courseTypeLoad,
  courseTypes,
}) => {
  const courseTypeName = courseTypes.find(
    courseType => courseType._id === courseTypeId
  )?.name
  React.useEffect(() => {
    if (courseTypes.length == 0 || courseTypes[0].forKids == true) {
      courseTypeLoad()
    }
    if (courseTypeId) {
      courseFilter(courseTypeId)
    } else {
      navigate("/")
    }
  }, [])

  return (
    <>
      <SEO title={`Culture House ${courseTypeName}`} />
      {courseTypeName === "リモート" && (
        <Link to="/online-guide" className={styles.onlineGuideLink}>
          <span>オンライン講座ガイド</span>
          <span className={styles.arrow}></span>
        </Link>
      )}
      <h1 className={styles.title}>{courseTypeName}</h1>
      {courseAbstracts?.map(course => (
        <CourseCard key={course._id} course={course} />
      ))}
    </>
  )
}

export default connector(CourseType)
