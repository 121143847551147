import React from "react"
import { Router } from "@reach/router"
import { withPrefix } from "gatsby"

import { Layout } from "../../components"
import CourseType from "./course-type"

const Index: React.FC = () => {
  return (
    <Layout>
      <div className="container">
        <Router basepath={withPrefix("course-type")}>
          <CourseType path="/:courseTypeId" />
        </Router>
      </div>
    </Layout>
  )
}

export default Index
